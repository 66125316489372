<template>
  <div>
    <div>
      <div>
        <breadcrumb
          name="Extended Warranty"
          title="Extended Warranty List"
          subtitle="Add New"
          @onTitleClicked="viewList"
        />
      </div>
      <div class="body">
        <vs-row style="padding-right: 20px; padding-left: 20px">
          <vs-col vs-lg="6" vs-md="12">
            <vs-input
              label="English Name *"
              v-model="name_en"
              size="medium"
              class="input"
              danger-text="This field is mandatory"
            />
          </vs-col>
          <vs-col vs-lg="6" vs-md="12">
            <vs-input
              label="Arabic Name *"
              v-model="name_ar"
              size="medium"
              class="input"
              danger-text="This field is mandatory"
            />
          </vs-col>

          <vs-col vs-lg="6" vs-md="12">
            <div class="switch">
              <label>Show On Mobile</label>
              <vs-switch v-model="active"/>
            </div>
          </vs-col>

          <vs-col vs-lg="12" vs-md="12">
            <img-upload
              :single="true"
              :images="image"
              title="Upload Image"
              @image_uploaded="saveImage"
              :key="imagesCounter"
            />
          </vs-col>
        </vs-row>
        <div class="optionBtns">
          <vs-button
            class="mr-5 save"
            icon-pack="feather"
            icon="icon-save"
            :disabled="invalidForm"
            @click="confirmSaveData"
          >Save
          </vs-button>
          <vs-button
            class="ml-5 cancel"
            type="border"
            color="primary"
            @click="resetData"
          >Cancel
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Breadcrumb from "../../../components/general/breadcrumb";
  import ImgUpload from "../../../components/general/img-upload";
  import UploadFile from "../../../components/uploader/upload-file";

  export default {
    name: "add-categories",
    components: {ImgUpload, Breadcrumb, UploadFile},
    data() {
      return {
        name_en: "",
        name_ar: "",
        image: [],
        active: true,
        imagesCounter: 0
      };
    },
    computed: {
      invalidForm() {
        if (this.name_en && this.name_ar && this.image.length != 0) {
          return false;
        }
        return true;
      }
    },
    methods: {
      saveImage(images) {
        this.image = images;
        this.imagesCounter++;
      },
      viewList() {
        this.$emit("cancel");
      },
      confirmSaveData() {
        this.$vs.dialog({
          title: "Confirm",
          text: "You are going to save these data!",
          color: "primary",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      resetData() {
        this.image = null;
        this.$emit("cancel");
      },
      saveData() {
        this.$vs.loading();
        let formData = new FormData();

        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);

        if (this.image.length != 0) {
          formData.append("image", this.image.toString());
        }

        formData.append("active", this.active ? "1" : "0");

        this.$http
          .post(`/warranties`, formData)
          .then(r => {
            this.$emit("saved");
            this.$vs.loading.close();
            console.log(r);
            this.$vs.notify({
              title: "Saved",
              text: "Data is Saved Successfully",
              color: "primary"
            });
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Error is occurred!\nCouldn't Save Data!",
              color: "danger"
            });
          });
      }
    }
  };
</script>

<style scoped lang="scss">
  .clr {
    clear: both;
  }

  .body {
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;

    .drop-down {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    label {
      font-size: 1rem;
      padding: 5px;
    }

    select {
      padding: 10px;
      border-radius: 5px;
      width: 90%;
      background-color: #fff;
      border: 1px solid #00000030;
    }

    .inputs {
      margin: 20px 0 20px 0;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
    }
  }

  .input {
    width: 90%;
    margin-top: 30px;
  }

  .switch {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
  }

  .checkboxes {
    margin: 10px;

    .checkbox {
      margin: 5px;
    }
  }

  .optionInput {
    margin-top: 30px;
    width: 50%;
    display: block;
    padding: 10px;
    border-radius: 5px;
    color: #00000090;
    margin-left: 10px;
  }

  h2 {
    margin: 30px;
  }

  .optionBtns {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  h4 {
    padding: 20px;
  }

  .subtitle {
    margin-top: 50px;
    color: #ccc;
  }

  .input {
    width: 90%;
    margin-top: 10px;
  }
</style>
